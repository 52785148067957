.image-gallery-fullscreen-button,
.image-gallery-play-button {
  padding: 0 30px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  margin:0;
  padding:0;
  height:auto;
}

.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  display: block;
  margin-top: -36px;
}

@media (max-width: 960px) {
    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
      margin-top: -21px;
    }
}

@media (max-width: 780px) {
    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
        margin-top: -15px;
    }
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 0 20px;
    }
}


@media (max-width: 640px) {
    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
        margin-top: -12px;
    }
}


.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
    text-shadow: 0px 0px 6px #000;
}


.fullscreen .image-gallery-fullscreen-button,
.fullscreen .image-gallery-play-button {
    position: fixed;
}

.image-gallery-content,
.image-gallery-content.fullscreen {
    background: transparent url("https://www.lelum.us/static/media/brick-tile.697e7222674058faa5ca.jpg") !important;
}

.image-gallery-slide.image-vertical img {
    height: auto;
    width: 45%;
}

.fullscreen .image-gallery-slide.image-vertical img {
    height: 100%;
    width: 43%;
}

.fullscreen .image-gallery.loida-and-radek-wedding {
    background-image: none;
}

.image-gallery-content.fullscreen .image-gallery-slide,
.image-gallery-slide {
    background: transparent;
}

.fullscreen .image-gallery-slide {
    background-image: none;
}
