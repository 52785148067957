
@import url('https://fonts.googleapis.com/css?family=Arima+Madurai:400,600,800,900|Open+Sans');

html {
  background: white url("../img/brick-tile.jpg");
}

body {
    font-family: 'Open Sans', sans-serif;
}

@media (max-width: 960px) {
  body {
    font-size: 1.2em;
  }
}

@media (max-width: 640px) {
  body {
   font-size: 1em;
  }
}

h2 {
    font-family: 'Arima Madurai', cursive;
    font-weight: 600;
    font-size: 2.2em;
}

h3 {
    font-family: 'Arima Madurai', cursive;
    font-weight: 600;
    font-size: 1.8em;
}

h4 {
    font-family: 'Arima Madurai', cursive;
    font-weight: 600;
    font-size: 1.75em;
}

h5 {
    font-family: 'Arima Madurai', cursive;
    font-weight: 600;
    font-size: 1.6em;
}

ul li:nth-child(odd) {
    margin-bottom:0;
}

ul li:nth-child(even) {
    color: transparent;
}

.pictures ul li:nth-child(even) {
  color: inherit;
  list-style-type: none;
}

ul li {
    list-style-position: outside;
    margin-left:1em;
}

ul li strong {
    color: #222;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: .8em;
}

ul h5 {
    margin: 0 1rem 0 0;
}

ul h5:first-child {
    margin-top: 1rem;
}

ul h5:last-child {
    margin-bottom: 1rem;
}

.shell {
    margin: 0 20%;
    max-width: 1200px;
}

@media (max-width: 1400px) {
  .shell {
    margin: 0 14%;
  }
}

@media (max-width: 1200px) {
  .shell {
    margin: 0 10%;
  }
}

@media (max-width: 640px) {
  .shell {
    margin: 0 4%;
  }
}

.shell-01 {
  padding: 0 2em 0 0;
  background: url("../img/border-01.jpg") repeat-y right;
}

.shell-02 {
  padding: 0 0 0 2em;
  background: url("../img/border-02.jpg") repeat-y left;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
}

header {
  color: #fff;
  position: relative;
}

header container {
    position: static;
}

main.container {
    padding: 0 0 3em 0;
}

@media (max-width: 400px) {
    main.container > div {
        padding: 0 2em;
    }
}

footer {
  background: linear-gradient(#a9d38d, #b7a26d 40%);
  padding: 6em 0 .5em;
  text-align: center;
  color: #fff;
}

a {
  color: #8d724b;
  text-decoration: none;
}

main {
  flex: 1 0 auto;
}

dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
dt {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
}
dd {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.navig {
    position: absolute;
    bottom: 0;
}

@media (max-width: 960px) {
  .navig {
    margin: -24px 0 0 0;
  }
}

@media (max-width: 640px) {
  .navig {
    margin: -20px 0 0 0;
  }

  nav li {
      font-size: .5em;
  }
}

nav {
  display: inline-block;
}

nav ul {
  bottom: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  float: left;
  margin: 0;
  font-family: 'Arima Madurai', cursive;
  font-weight: 800;
  font-size: 1em;
}

@media (max-width: 640px) {
  nav li {
      font-size: 1em;
  }
}

@media (max-width: 1200px) {
  nav li {
      font-size: 0.93em;
  }
}

nav li a {
  display: block;
  color: #fff;
  text-align: center;
  padding: .25em .65em 0 .65em;
  text-decoration: none;
  background-color: #b7a26d;
  margin-right: 1px;
  border-radius: 10px 10px 0 0;
  opacity: 1;
}

nav li a:hover {
    color: white;
    background-color: #444;
}

nav li.on a,
nav li.bckg-admin.on a{
  background-color: #e9917e;
  color: white;
  opacity: 1;
}

nav li.on a:hover {
    cursor: default;
}

nav li.bckg-admin a{
    background-color: #a9d38d;
}

.button.button-primary.button-login {
  position: absolute;
  background-color: #1EAEDB;
  top: -3px;
  right:0;
  font-family: 'Arima Madurai';
  font-weight: 800;
  font-size: 1em;
  height: auto;
  padding: 6px 15px 2px 15px;
  line-height: normal;
  text-transform: capitalize;
}

@media (max-width: 960px) {
  .button.button-primary.button-login {
      padding: 5px 12px 2px 12px;
  }
}

@media (max-width: 640px) {
  .button.button-primary.button-login {
      padding: 4px 10px 1px 10px;
  }
}

.button.button-primary.button-login:hover,
.button.button-primary.button-login.on {
    background-color: #e9917e;
    border-color: #e9917e;
}

.button.button-primary {
    background-color: #e9917e;
}

.logo {
  text-align: center;
}

.logo img {
  width: 100%;
}

.center {
  text-align: center;
}

.right {
    text-align: right;
}

.editarea {
  height: 12em;
}

.list {
  padding-bottom: 2em;
}

.formError {
  color: #cb3a49;
}

.quote h1 {
  color: #1ccea8;
}

.breath {
  margin: 1em 0;
}
.highlight {
  color: #a89131;
}

.rsvp-cont {
    text-align: center;
    margin: 0 auto 5em;
}

.rsvp-cont * {
    display: inline-block;
    vertical-align: middle;
}

.ordinar {
    padding: 2.4em 0 0 0;
}

.nodisplay {
    display: none;
}

.invite,
button.invite {
  color: #fff;
  height: 2em;
  font-size: 2em;
  background-color: #0086CB;
}

.invite:hover,
button.invite:hover {
    color: #444;
}

.invite.positive {
  font-size: 2.2em;
  padding: 0 .7em 0 .7em;
  margin:0;
  border: 0 none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.invite.negative:active,
.invite.positive:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.invite.positive:disabled,
.invite.positive[disabled],
.invite.negative:disabled,
.invite.negative[disabled]{
    background-color: #005C8B;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
    color: #000;
}

.invite.negative {
  font-size: 2.2em;
  padding: 0 .7em .2em .7em;
  margin:0;
  border: 0 none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.invite.value {
    color: #333;
  height: 2em;
  width: 4em;
  margin: 10px 1em 0 1em;
  text-align:center;
  border-width: 4px;
  border-radius: 15px;
}

.invite-confirm.positive {
    background-color: #a9d38d;
    font-size: 1.5em;
    height:3em;
    border: 0 none;
    color: #fff;
    margin: 1em;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.invite-confirm.positive:disabled,
.invite-confirm.positive[disabled] {
    background-color: #769362;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
    color: #000;
}

.invite-confirm.negative {
    background-color: #e9917e;
    font-size: 1.5em;
    height:3em;
    border: 0 none;
    color: #fff;
    margin: 1em;
    border-radius: 15px;
  box-shadow: 0 9px #999;
}

.invite-confirm.negative:disabled,
.invite-confirm.negative[disabled] {
    background-color: #a9695c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
        color: #000;
}

.login {
    margin-top: 3em;
}

.intro-image {
    float: right;
    width: 34%;
    padding-left:1em;
}

@import 'loader.css';

@import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';

@import 'image-gallery-overwrite.css';
