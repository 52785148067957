.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #acf;
  font-size: 10px;
  margin: -2em 2.5em 0 0;
  position: absolute;
  right:0;
  top:0;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

input[type="submit"].in-progress,
input[type="submit"].in-progress:hover,
button.in-progress,
button.in-progress:hover,
.in-progress,
.in-progress:hover {
    color: #fff;
    cursor: default;
    border-color: transparent;
    background: linear-gradient(314deg, #0086cb, #a9d38d);
    background-size: 400% 400%;
    -webkit-animation: in-progress 2s ease infinite;
    -moz-animation: in-progress 2s ease infinite;
    -o-animation: in-progress 2s ease infinite;
    animation: in-progress 2s ease infinite;
}

@-webkit-keyframes in-progress {
    0%{background-position:0% 11%}
    50%{background-position:100% 90%}
    100%{background-position:0% 11%}
}
@-moz-keyframes in-progress {
    0%{background-position:0% 11%}
    50%{background-position:100% 90%}
    100%{background-position:0% 11%}
}
@-o-keyframes in-progress {
    0%{background-position:0% 11%}
    50%{background-position:100% 90%}
    100%{background-position:0% 11%}
}
@keyframes in-progress {
    0%{background-position:0% 11%}
    50%{background-position:100% 90%}
    100%{background-position:0% 11%}
}

.feedback {
    padding: 0 1em;
    opacity: 0;
}

.after-progress {
    opacity: 0;
    animation: fade 5s ease-out;
}

@-webkit-keyframes fade {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}
@-moz-keyframes fade {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}
@-o-keyframes fade {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}
@keyframes fade {
    0%,100% { opacity: 0; }
    40% { opacity: 1; }
}

.loading {
  padding-top: 8.2rem;
  background-color: #0086cb;
  color: #ccc;
  padding-bottom: 0.3rem;
  font-weight: bold;
  text-align: left;
  padding-left: 2rem;

  background: linear-gradient(257deg, #0086cb, #a9d38d);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}

.loading-spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.loading-container {
    height: 200px;
    margin: 1em auto;
    width: 200px;
}

.loading-spinner-path {
    stroke: #0086cb;
    stroke-dasharray: 325;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash 1.4s ease-in-out infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 187 / 4;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
